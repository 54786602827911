import moment from 'moment'
import { useEffect, useState } from 'react'
import EditorFooter from '../../components/formComponents/editorFooter/EditorFooter'
import FormComponent from '../../components/formComponents/formComponent/FormComponent'
import LanguageSelector from '../../components/languageSelector/LanguageSelector'
import { IComponent, IKeyValue } from '../../models/IComponent'
import { Utils } from '../../utils/Utils'
import WebView from '../webView/WebView'
import './EditorView.scss'

function EditorView({ selectedLanguage, setSelectedLanguage, isSeasonEffect }) {
    const keyValuePair = Utils.getKeyValue()

    const { type, backgroundColor, defaultProductName } = Utils.getDefaultParameters()
    const [manufacturerName, setManufacturerName] = useState<string>('inevvo')
    const [productName, setProductName] = useState<string>(defaultProductName)
    const [serialNumber, setSerialNumber] = useState<string>('123456789')
    const [materialNumber, setMaterialNumber] = useState<string>('987654321')
    const [productionDate, setProductionDate] = useState<string>(moment().format('YYYY-MM-DD'))
    const [productIcon, setProductIcon] = useState<{ iconFile: File, iconUrl: string }>({ iconFile: new File([], ''), iconUrl: '' })
    const [manufacturerIcon, setManufacturerIcon] = useState<{ iconFile: File, iconUrl: string }>({ iconFile: new File([], ''), iconUrl: '' })
    const [manufacturerColor, setManufacturerColor] = useState<string>(backgroundColor)
    const [manufacturerUrl, setManufacturerUrl] = useState<string>('https://inevvo-solutions.com')
    const [keyValues, setKeyValues] = useState<IKeyValue[]>(keyValuePair)
    const [ownerEmail, setOwnerEmail] = useState<string>('')
    const [documents, setDocuments] = useState<File[]>([])
    const [isAdvertisingAgreed, setIsAdvertisingAgreed] = useState(false)
    const [windowWidth, setWindowWidth] = useState(0);



    const commonProps = {
        manufacturerName,
        setManufacturerName,
        productName,
        setProductName,
        serialNumber,
        setSerialNumber,
        materialNumber,
        setMaterialNumber,
        productionDate,
        setProductionDate,
        productIcon,
        setProductIcon,
        manufacturerIcon,
        setManufacturerIcon,
        manufacturerColor,
        setManufacturerColor,
        manufacturerUrl,
        setManufacturerUrl,
        keyValues,
        setKeyValues,
        ownerEmail,
        setOwnerEmail,
        documents,
        setDocuments,
        isAdvertisingAgreed,
        setIsAdvertisingAgreed,
        type
    };

    const component: IComponent = {
        manufacturerName: manufacturerName,
        productName: productName,
        serialNumber: serialNumber,
        materialNumber: materialNumber,
        productionDate: productionDate,
        productIcon: productIcon.iconUrl,
        manufacturerIcon: manufacturerIcon.iconUrl,
        manufacturerColor: manufacturerColor,
        manufacturerUrl: manufacturerUrl,
        keyValue: keyValues,
        ownerEmail: ownerEmail,
        document: documents,
        isAdvertisingAgreed: isAdvertisingAgreed,
        type: type
    }
    // Set title of the page
    document.title = productName

    // aspect ratio does not work on ios
    // That's why we use the height of the parent div
    const mobileDeviceBreakPoint = 769;
    const isMobile = window.innerWidth < mobileDeviceBreakPoint;
    let isIos = false;
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('mac') || userAgent.includes('iphone') || userAgent.includes('ipad')) {
        isIos = true;
    }

    const aspectRatio = 5 / 8; // Desired aspect ratio (width/height)
    const containerHeight = window.innerWidth * 0.45 / aspectRatio;


    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    const inlineStyleForIos = {
        height: isIos && !isMobile ? `${containerHeight}px` : 'auto',
        maxHeight: isIos && !isMobile ? '90vh' : 'auto'
    }

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    return (
        <div className="editor_wrapper">
            <div className="editor_wrapper_2">
                <div className="language_selector">
                    <LanguageSelector
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                    />
                </div>
                <div className="editor_content_wrapper">
                    <div className="editor_form_wrapper">
                        <FormComponent {...commonProps} />
                    </div>
                    <div className="editor_preview_wrapper" >
                        <div className="editor_preview"
                            style={inlineStyleForIos}>
                            <WebView isSeasonEffect={isSeasonEffect} component={component} isEditorView={true} />
                        </div>
                    </div>
                </div>
                <EditorFooter />
            </div>
        </div >
    )
}

export default EditorView