import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import './KeyValues.scss';
import { FormTexts } from '../../../language/Texts';
import { IKeyValue } from '../../../models/IComponent';

interface KeyValuesProps {
    keyValues: {
        key: string;
        value: string;
    }[],
    setKeyValues: (keyValues: IKeyValue[]) => void,
}
function KeyValues({ keyValues, setKeyValues }: KeyValuesProps) {
    const handleInputChange = (index: number, field: 'key' | 'value', newValue: string) => {
        const updatedKeyValues = [...keyValues];
        updatedKeyValues[index][field] = newValue;
        setKeyValues(updatedKeyValues);
    };

    const handleAddFields = () => {
        setKeyValues([...keyValues, { key: '', value: '' }]);
    };

    const handleRemoveFields = (index) => {
        if (index >= 0 && index < keyValues.length) {
            const updatedKeyValues = [...keyValues];
            updatedKeyValues.splice(index, 1);
            setKeyValues(updatedKeyValues);
        }
    };

    return (
        <div className="input_pair">
            <label className='input_label'>{FormTexts.keyValues}</label>
            {keyValues?.map((kv, index) => (
                <div key={index} className='kv_input_wrapper'>
                    <div className="input_wrapper">
                        <input
                            className='input_field kv_input'
                            type="text"
                            placeholder="Key"
                            value={kv.key}
                            onChange={(e) => handleInputChange(index, 'key', e.target.value)}
                        />
                        <input
                            className='input_field kv_input'
                            type="text"
                            placeholder="Value"
                            value={kv.value}
                            onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                        />
                    </div>
                    <Button className='remove_icon_wrapper' onClick={() => handleRemoveFields(index)}>
                        <FontAwesomeIcon className="remove_icon" icon={faCircleMinus} />
                    </Button>

                </div>
            ))}
            <Button className='add_icon_wrapper' onClick={handleAddFields}>
                <FontAwesomeIcon className="add_icon" icon={faCirclePlus} />
            </Button>
        </div>
    )
}

export default KeyValues
